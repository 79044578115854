<template>
    <v-container>
        <v-layout column class="panel-wrapper ma-5 pa-5">
            <v-flex xs12 style="text-align: center">
                <div>
                    <img src="./_source/fraud.png" alt="Fraud Client" style="width: 150px;height: 150px;">
                </div>
                <h1 class="my-3">Order Rejected</h1>
                <p>Thank you for your interest in our service. Unfortunately, we are unable to extend you the opportunity to do service with our company because of one of the following reasons:</p>
                <div style="margin-bottom: 16px;">
                    <ul style="display: inline-block;list-style: decimal; text-align: left;">
                        <li><span>You failed the identity verification.</span></li>
                        <li><span>The product is currently not available.</span></li>
                    </ul>
                </div>
                <p>Please feel free to contact us if you have further questions.</p>
                <!--<p>Thank you for your interest in our service. Unfortunately, we are unable to extend you the opportunity to do service with our company since you failed the identity verification. But If you are within the free trial period, you can continue using the free-trial app until the end of the trial. Please feel free to contact us if you have further questions.</p>-->
                <v-btn class="my-3 primary" to="/">GO TO HOME PAGE</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "Fraud"
    }
</script>

<style scoped>

</style>
