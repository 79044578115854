var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "v-container",
        { staticClass: "checkout" },
        [
          _c("h2", { staticClass: "mb-3 mt-1" }, [
            _vm._v("Order Confirmation")
          ]),
          _c(
            "v-layout",
            {
              staticClass: "panel-wrapper pa-3",
              staticStyle: { "background-color": "#fff" },
              attrs: { wrap: "" }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md6: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "fs-2 panel-title",
                          staticStyle: { color: "#333" }
                        },
                        [_vm._v("Plan")]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pb-0" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-1",
                              attrs: { row: "" },
                              model: {
                                value: _vm.planName,
                                callback: function($$v) {
                                  _vm.planName = $$v
                                },
                                expression: "planName"
                              }
                            },
                            _vm._l(_vm.plansNameList, function(name, idx) {
                              return _c("v-radio", {
                                key: idx,
                                staticClass: "py-1",
                                attrs: { value: name, color: "primary" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular",
                                              class: {
                                                colored: _vm.planName === name
                                              }
                                            },
                                            [_vm._v(_vm._s(name))]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "py-2",
                              staticStyle: {
                                "padding-left": "28px",
                                "background-color": "#E9EEFF",
                                width: "523px"
                              },
                              attrs: { wrap: "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [_vm._v("CPU Cores:")]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.currentPlan.cpu_description)
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [_vm._v("Memory:")]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.currentPlan.memory_description)
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [_vm._v("Disk:")]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.currentPlan.disk_description)
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [_vm._v("Backup Storage:")]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentPlan.backup_storage_description
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 pt-1",
                                  attrs: { xs6: "" }
                                },
                                [
                                  _vm._v("Replica"),
                                  _vm.currentPlan.instance !== 1
                                    ? [_vm._v("s")]
                                    : _vm._e(),
                                  _vm._v(":")
                                ],
                                2
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "font-weight-regular fs-3 py-1",
                                  attrs: { xs6: "" }
                                },
                                [_vm._v(_vm._s(_vm.currentPlan.instance))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.versions.length
                        ? [
                            _c(
                              "v-flex",
                              {
                                staticClass: "fs-2 panel-title",
                                staticStyle: { color: "#333" },
                                attrs: { "pt-3": "" }
                              },
                              [_vm._v("Version")]
                            ),
                            _c(
                              "v-flex",
                              { staticClass: "pb-0" },
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    staticClass: "mt-1",
                                    attrs: { row: "" },
                                    model: {
                                      value: _vm.vers,
                                      callback: function($$v) {
                                        _vm.vers = $$v
                                      },
                                      expression: "vers"
                                    }
                                  },
                                  _vm._l(_vm.versions, function(ver) {
                                    return _c("v-radio", {
                                      key: ver.id,
                                      staticClass: "py-1",
                                      attrs: {
                                        value: ver.id,
                                        color: "primary"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fs-3 font-weight-regular",
                                                    class: {
                                                      colored:
                                                        _vm.vers === ver.id
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.productDetail.name +
                                                          "@" +
                                                          ver.number
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      [
                        _c(
                          "v-flex",
                          {
                            staticClass: "fs-2 panel-title",
                            staticStyle: { color: "#333" }
                          },
                          [_vm._v("Component")]
                        ),
                        _c(
                          "v-flex",
                          { staticClass: "pb-0" },
                          [
                            _c(
                              "v-radio-group",
                              {
                                staticClass: "mt-1",
                                attrs: { row: "" },
                                model: {
                                  value: _vm.img_id,
                                  callback: function($$v) {
                                    _vm.img_id = $$v
                                  },
                                  expression: "img_id"
                                }
                              },
                              _vm._l(_vm.images, function(item) {
                                return _c("v-radio", {
                                  key: item.id,
                                  staticClass: "py-1",
                                  attrs: { value: item.id, color: "primary" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fs-3 font-weight-regular",
                                                class: {
                                                  colored:
                                                    _vm.img_id === item.id
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      item.components
                                                        .map(function(o) {
                                                          return (
                                                            o.name +
                                                            "@" +
                                                            o.version
                                                          )
                                                        })
                                                        .join("__")
                                                    ) +
                                                    "\n                                    "
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      _c(
                        "v-flex",
                        {
                          staticClass: "fs-2 panel-title",
                          staticStyle: { color: "#333" }
                        },
                        [_vm._v("Location")]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pb-0" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-1",
                              attrs: { row: "" },
                              model: {
                                value: _vm.location,
                                callback: function($$v) {
                                  _vm.location = $$v
                                },
                                expression: "location"
                              }
                            },
                            _vm._l(_vm.locationList, function(item, idx) {
                              return _c("v-radio", {
                                key: idx,
                                staticClass: "py-1 mt-1",
                                attrs: {
                                  value: item.location_id,
                                  color: "primary"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular",
                                              class: {
                                                colored:
                                                  _vm.location ===
                                                  item.location_id
                                              }
                                            },
                                            [_vm._v(_vm._s(item.region))]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "fs-2 panel-title",
                          staticStyle: { color: "#333" }
                        },
                        [_vm._v("Billing Cycle")]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pb-0" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-1",
                              attrs: { row: "" },
                              model: {
                                value: _vm.billing_value,
                                callback: function($$v) {
                                  _vm.billing_value = $$v
                                },
                                expression: "billing_value"
                              }
                            },
                            _vm._l(_vm.currentPlan.prices_set, function(
                              billing,
                              idx
                            ) {
                              return _c("v-radio", {
                                key: billing.duration,
                                staticClass: "py-1",
                                attrs: { value: idx, color: "primary" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular",
                                              class: {
                                                colored:
                                                  _vm.billing_value === idx
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(billing.title) +
                                                  ": $" +
                                                  _vm._s(
                                                    billing.price_base.toFixed(
                                                      2
                                                    )
                                                  ) +
                                                  " / Month"
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "fs-2 panel-title",
                          staticStyle: { color: "#333" }
                        },
                        [_vm._v("Payment Method")]
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pb-0" },
                        [
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "mt-1",
                              attrs: { row: "" },
                              model: {
                                value: _vm.payment,
                                callback: function($$v) {
                                  _vm.payment = $$v
                                },
                                expression: "payment"
                              }
                            },
                            _vm._l(_vm.paymentMethods, function(item, idx) {
                              return _c("v-radio", {
                                key: idx,
                                staticClass: "py-1",
                                attrs: { value: idx, color: "primary" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular",
                                              class: {
                                                colored: _vm.payment === idx
                                              }
                                            },
                                            [_vm._v(_vm._s(item))]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        [
                          _c("v-textarea", {
                            staticClass: "mt-1",
                            attrs: {
                              "background-color": "#f1f6f6",
                              label: "Notes",
                              placeholder:
                                "Please enter your additional notes or requirements...",
                              box: "",
                              value: ""
                            },
                            model: {
                              value: _vm.notes,
                              callback: function($$v) {
                                _vm.notes = $$v
                              },
                              expression: "notes"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm12: "", md5: "", "offset-md1": "" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end"
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { staticStyle: { width: "400px" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-layout",
                                { attrs: { column: "", "pl-2": "" } },
                                [
                                  _c("v-flex", { staticClass: "mb-3 " }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "font-weight-bold fs-2",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [_vm._v("Order Summary")]
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [_vm._v("Application")]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                " fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.productDetail.name)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [_vm._v("Plan")]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                " fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.currentPlan.name)
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [_vm._v("Location")]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                " fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.currentLocation.region
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [_vm._v("Billing Cycle")]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                " fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.currentPlan.prices_set[
                                                    _vm.billing_value
                                                  ].title
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [_vm._v("Payment Method")]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                " fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.paymentMethods[
                                                    _vm.payment
                                                  ]
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [_vm._v("Total")]
                                          ),
                                          _vm.currentPlan.prices_set
                                            ? _c(
                                                "v-flex",
                                                {
                                                  staticClass: " py-2",
                                                  staticStyle: {
                                                    display: "inline-block",
                                                    color: "rgb(64,157,226)",
                                                    "font-size": "24px",
                                                    "font-weight": "bold"
                                                  },
                                                  attrs: { xs6: "" }
                                                },
                                                [
                                                  _vm._v("$ "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentPlan.prices_set[
                                                          _vm.billing_value
                                                        ].total_price.toFixed(2)
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [_vm._v("Notes")]
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                " fs-3 font-weight-regular py-2",
                                              attrs: { xs6: "" }
                                            },
                                            [_vm._v(_vm._s(_vm.notes))]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", md12: "" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "py-3",
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ma-0 text-none",
                                                  attrs: {
                                                    color: "primary",
                                                    large: "",
                                                    loading: _vm.loading
                                                  },
                                                  on: { click: _vm.checkOut }
                                                },
                                                [_vm._v("Check Out")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("pending-account-notice", {
            ref: "pending",
            attrs: { isCheck: true }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }