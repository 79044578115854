var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { staticClass: "panel-wrapper ma-5 pa-5", attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticStyle: { "text-align": "center" }, attrs: { xs12: "" } },
            [
              _c("div", [
                _c("img", {
                  staticStyle: { width: "150px", height: "150px" },
                  attrs: {
                    src: require("./_source/fraud.png"),
                    alt: "Fraud Client"
                  }
                })
              ]),
              _c("h1", { staticClass: "my-3" }, [_vm._v("Order Rejected")]),
              _c("p", [
                _vm._v(
                  "Thank you for your interest in our service. Unfortunately, we are unable to extend you the opportunity to do service with our company because of one of the following reasons:"
                )
              ]),
              _c("div", { staticStyle: { "margin-bottom": "16px" } }, [
                _c(
                  "ul",
                  {
                    staticStyle: {
                      display: "inline-block",
                      "list-style": "decimal",
                      "text-align": "left"
                    }
                  },
                  [
                    _c("li", [
                      _c("span", [
                        _vm._v("You failed the identity verification.")
                      ])
                    ]),
                    _c("li", [
                      _c("span", [
                        _vm._v("The product is currently not available.")
                      ])
                    ])
                  ]
                )
              ]),
              _c("p", [
                _vm._v(
                  "Please feel free to contact us if you have further questions."
                )
              ]),
              _c("v-btn", { staticClass: "my-3 primary", attrs: { to: "/" } }, [
                _vm._v("GO TO HOME PAGE")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }