var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "v-container",
        { staticClass: "free-trial-page" },
        [
          _c("h2", { staticClass: "mb-3 mt-1" }, [
            _vm._v(
              _vm._s(_vm.currentPlan.name) +
                " " +
                _vm._s(_vm.product) +
                " Cloud Hosting Free Trial"
            )
          ]),
          _c(
            "v-layout",
            { staticClass: "panel-wrapper pa-4", attrs: { column: "" } },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "fs-2 panel-title",
                          attrs: { md12: "", sm12: "", xs12: "" }
                        },
                        [_c("span", [_vm._v("Plan")])]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md12: "", sm12: "", xs12: "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.planIdx,
                                callback: function($$v) {
                                  _vm.planIdx = $$v
                                },
                                expression: "planIdx"
                              }
                            },
                            _vm._l(_vm.productDetail.plans, function(
                              plans,
                              idx
                            ) {
                              return _c("v-radio", {
                                key: plans.uuid,
                                staticClass: "py-1",
                                attrs: { color: "primary", value: idx },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-regular fs-3",
                                              class: {
                                                colored: _vm.planIdx === idx
                                              }
                                            },
                                            [_vm._v(_vm._s(plans.name))]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "py-2",
                      staticStyle: {
                        "padding-left": "32px",
                        "background-color": "#E9EEFF",
                        width: "523px"
                      },
                      attrs: { wrap: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v("CPU Cores:")]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v(_vm._s(_vm.currentPlan.cpu_description))]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v("Memory:")]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v(_vm._s(_vm.currentPlan.memory_description))]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v("Disk:")]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v(_vm._s(_vm.currentPlan.disk_description))]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v("Backup Storage:")]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.currentPlan.backup_storage_description)
                          )
                        ]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 pt-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v("Replica:")]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "font-weight-regular fs-3 py-1",
                          attrs: { xs6: "" }
                        },
                        [_vm._v(_vm._s(_vm.currentPlan.instance))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.versions.length
                ? [
                    _c(
                      "v-flex",
                      { staticClass: "pt-3" },
                      [
                        _c(
                          "v-layout",
                          { attrs: { column: "" } },
                          [
                            _c("v-flex", { staticClass: "fs-2 panel-title" }, [
                              _c("span", [_vm._v("Version")])
                            ]),
                            _c(
                              "v-flex",
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { row: "" },
                                    model: {
                                      value: _vm.vers,
                                      callback: function($$v) {
                                        _vm.vers = $$v
                                      },
                                      expression: "vers"
                                    }
                                  },
                                  _vm._l(_vm.versions, function(ver) {
                                    return _c("v-radio", {
                                      key: ver.id,
                                      staticClass: "py-1",
                                      attrs: {
                                        value: ver.id,
                                        color: "primary"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-regular fs-3",
                                                    class: {
                                                      colored:
                                                        _vm.vers === ver.id
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.productDetail.name +
                                                          "@" +
                                                          ver.number
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showComponent,
                            expression: "showComponent"
                          }
                        ]
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { column: "" } },
                          [
                            _c("v-flex", { staticClass: "fs-2 panel-title" }, [
                              _c("span", [_vm._v("Component")])
                            ]),
                            _c(
                              "v-flex",
                              [
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { row: "" },
                                    model: {
                                      value: _vm.img_id,
                                      callback: function($$v) {
                                        _vm.img_id = $$v
                                      },
                                      expression: "img_id"
                                    }
                                  },
                                  _vm._l(_vm.images, function(item) {
                                    return _c("v-radio", {
                                      key: item.id,
                                      staticClass: "py-1",
                                      attrs: {
                                        value: item.id,
                                        color: "primary"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-regular fs-3",
                                                    class: {
                                                      colored:
                                                        _vm.img_id === item.id
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          item.components
                                                            .map(function(o) {
                                                              return (
                                                                o.name +
                                                                "@" +
                                                                o.version
                                                              )
                                                            })
                                                            .join("__")
                                                        ) +
                                                        "\n                                        "
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "v-flex",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", { staticClass: "fs-2 panel-title" }, [
                        _c("span", [_vm._v("Location")])
                      ]),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.location,
                                callback: function($$v) {
                                  _vm.location = $$v
                                },
                                expression: "location"
                              }
                            },
                            _vm._l(_vm.locationList, function(item, idx) {
                              return _c("v-radio", {
                                key: idx,
                                staticClass: "py-1",
                                attrs: {
                                  value: item.location_id,
                                  color: "primary"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "fs-3 font-weight-regular",
                                              class: {
                                                colored:
                                                  _vm.location ===
                                                  item.location_id
                                              }
                                            },
                                            [_vm._v(_vm._s(item.region))]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", { staticClass: "fs-2 panel-title py-1" }, [
                        _vm._v(
                          "\n                        Price\n                    "
                        )
                      ]),
                      _c("v-flex", { staticClass: "pt-2" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              color: "rgb(64,157,226)",
                              "font-size": "30px",
                              "font-weight": "bold"
                            }
                          },
                          [
                            _vm._v(
                              "\n                            $0.00\n                        "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "margin-left": "10px"
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "block",
                                  color: "rgb(153,153,153)",
                                  "text-decoration": "line-through",
                                  "font-size": "13px",
                                  position: "relative",
                                  top: "8px"
                                }
                              },
                              [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.currentPlan.prices_set[0].price_base
                                    ) +
                                    "\n                            "
                                )
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "13px",
                                  color: "rgb(153,153,153)"
                                }
                              },
                              [_vm._v("Monthly")]
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c(
                    "v-layout",
                    { staticClass: "mt-3" },
                    [
                      _c("v-flex", { staticClass: "py-1" }, [
                        _c("span", [
                          _vm._v("Reminder: "),
                          _c(
                            "b",
                            { staticStyle: { color: "rgb(64,157,226)" } },
                            [
                              _vm._v(
                                "\n                            Permitted concurrent free-trial applications: ≤ " +
                                  _vm._s(
                                    _vm.clientInfo.permissions
                                      .trial_app_max_number
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "mt-2" },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", { staticClass: "fs-2 panel-title py-2" }),
                      _c(
                        "v-flex",
                        { staticClass: "py-1 " },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-0",
                              staticStyle: { "text-transform": "none" },
                              attrs: { color: "primary", loading: _vm.loading },
                              on: {
                                click: function($event) {
                                  return _vm.deploy()
                                }
                              }
                            },
                            [_vm._v("Confirm\n                        ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("pending-account-notice", {
                ref: "pending",
                attrs: { isCheck: true }
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }