<template>
    <v-container v-if="ready" class="free-trial-page">
        <h2 class="mb-3 mt-1">{{currentPlan.name}} {{product}} Cloud Hosting Free Trial</h2>
        <v-layout column class="panel-wrapper pa-4">
<!--            <v-flex>-->
<!--                <v-layout>-->
<!--                    <v-flex md12 sm12 xs12 class="panel-title ">-->
<!--                        Application-->
<!--                    </v-flex>-->
<!--                    <v-flex md12 sm12 xs12 class="small-title panel-title  colored">-->
<!--                        {{product}}-->
<!--                    </v-flex>-->
<!--                </v-layout>-->
<!--            </v-flex>-->
            <v-flex>
                <v-layout column>
                    <v-flex md12 sm12 xs12 class="fs-2 panel-title">
                        <span >Plan</span>
                    </v-flex>
                    <v-flex md12 sm12 xs12>
                        <v-radio-group v-model="planIdx" row>
                            <v-radio
                                    class="py-1"
                                    color="primary"
                                    v-for="(plans,idx) in productDetail.plans"
                                    :key="plans.uuid"
                                    :value="idx">
                                <template v-slot:label>
                                    <span class="font-weight-regular fs-3" :class="{colored: planIdx === idx}">{{plans.name}}</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex  >
                <v-layout  wrap class="py-2" style="padding-left: 32px; background-color: #E9EEFF;width: 523px;" >
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">CPU Cores:</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">{{currentPlan.cpu_description}}</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">Memory:</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">{{currentPlan.memory_description}}</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">Disk:</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">{{currentPlan.disk_description}}</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">Backup Storage:</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">{{currentPlan.backup_storage_description}}</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 pt-1">Replica:</v-flex>
                    <v-flex xs6 class="font-weight-regular fs-3 py-1">{{currentPlan.instance}}</v-flex>
                </v-layout>
            </v-flex>
            <template v-if="versions.length">
                <v-flex class="pt-3">
                    <v-layout column>
                        <v-flex  class="fs-2 panel-title">
                            <span >Version</span>
                        </v-flex>
                        <v-flex >
                            <v-radio-group v-model="vers" row>
                                <v-radio
                                        class="py-1"
                                        v-for="ver in versions"
                                        :key="ver.id"
                                        :value="ver.id" color="primary">
                                    <template v-slot:label>
                                        <span class="font-weight-regular fs-3" :class="{colored: vers === ver.id}">{{`${productDetail.name}@${ver.number}`}}</span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex v-show="showComponent">
                    <v-layout column>
                        <v-flex class="fs-2 panel-title">
                            <span >Component</span>
                        </v-flex>
                        <v-flex>
                            <v-radio-group v-model="img_id" row>
                                <v-radio v-for="item in images"
                                         class="py-1"
                                         :key="item.id"
                                         :value="item.id" color="primary">
                                    <template v-slot:label>
                                        <span class="font-weight-regular fs-3" :class="{colored: img_id === item.id}">
                                            {{ (item.components.map( o => `${o.name}@${o.version}`)).join('__') }}
                                        </span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </template>

            <v-flex>
                <v-layout column>
                    <v-flex class="fs-2 panel-title">
                        <span >Location</span>
                    </v-flex>
                    <v-flex >
                        <v-radio-group v-model="location" row>
                            <v-radio
                                    class="py-1"
                                    v-for="(item ,idx) in locationList"
                                    :key="idx"
                                    :value="item.location_id"
                                    color="primary">
                                <template v-slot:label>
                                    <span class="fs-3 font-weight-regular" :class="{colored: location === item.location_id}">{{item.region}}</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex>
                <v-layout column>
                    <v-flex  class="fs-2 panel-title py-1" >
                        Price
                    </v-flex>
                    <v-flex  class="pt-2">
                        <div style="display: inline-block;color:rgb(64,157,226);font-size: 30px;font-weight: bold;">
                            $0.00
                        </div>
                        <div style="display: inline-block;margin-left: 10px">
                            <span style="display:block;color: rgb(153,153,153);
                            text-decoration:line-through;font-size: 13px;position: relative;top: 8px;">${{currentPlan.prices_set[0].price_base}}
                            </span>
                            <span style="font-size: 13px;color: rgb(153,153,153);">Monthly</span>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>


            <v-flex >
                <v-layout class="mt-3">
                    <v-flex class="py-1">
                        <span>Reminder: <b style="color:rgb(64,157,226)">
                            Permitted concurrent free-trial applications: ≤ {{clientInfo.permissions.trial_app_max_number}}
                        </b></span>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex class="mt-2">
                <v-layout column>
                    <v-flex  class="fs-2 panel-title py-2">
                    </v-flex>
                    <v-flex  class="py-1 ">
                        <v-btn color="primary" :loading="loading" class="ma-0" v-on:click="deploy()"
                               style="text-transform: none">Confirm
                        </v-btn>
                        <!--  <v-btn outline segmented color="primary" @click="$router.push('/ordering')" style="text-transform: none">Back</v-btn>-->
                    </v-flex>
                </v-layout>
            </v-flex>
            <pending-account-notice ref="pending" :isCheck="true"></pending-account-notice>
        </v-layout>
    </v-container>
</template>

<script>
    import PendingAccountNotice from '@/components/BillingInfo/PendingAccountNotice'
    import {products} from '@/module/constant'
    import {mapGetters,mapState} from "vuex";

    export default {
        name: "FreeTrial",
        components:{PendingAccountNotice},
        props: ['clientStatus'],
        data() {
            return {
                loading: false,
                ready: false,
                productDetail: {},
                planIdx: 0,
                product: '',
                locationList: [],
                location: '',
                vers: null,
                images: [],
                img_id: null,
            }
        },
        computed: {
            showComponent() {
                return !(this.images.length === 1 && this.images[0].components.length === 1)
            },
            versions() {
                return this.productDetail.versions || []
            },
            plansUUList() {
                if (this.productDetail.plans) {
                    return this.productDetail.plans.map((item) => {
                        return item.uuid
                    });
                } else {
                    return []
                }
            },
            currentPlan() {
                return this.productDetail.plans[this.planIdx];
            },
            ...mapState('client',['clientInfo']),
            ...mapGetters('client', ['checkInfoCompleted']),
        },
        watch: {
            vers(newVal) {
                if (newVal) {
                    let ver = this.versions.find(o => o.id === newVal)
                    this.images = ver.images
                    this.img_id = ver.images[0].id
                } else {
                    this.images = []
                    this.img_id = null
                }
            }
        },
        methods: {
            getProduct() {
                return this.$http.get(`/products/${this.product}/`).then(res => {
                    res.plans.sort((a, b) => a.total_cpus - b.total_cpus)
                    this.productDetail = res
                })
            },
            getLocation() {
                return this.$http.get('orders/active-location/').then((res) => {
                    this.locationList = res
                })
            },
            deploy() {
                if (this.loading) return
                this.$store.commit('setState', {
                    payload: {haveOrder: ''}
                })
                this.loading = true
                this.$http.post(`/orders/free-trial/`, {
                    product_uuid: this.productDetail.uuid,
                    plan_uuid: this.currentPlan.uuid,
                    location_id: this.location,
                    image_id: this.img_id,
                    notes: ''
                }).then(({return_code}) => {
                    this.loading = false
                    if (return_code === 1) {
                        this.pending.open({isGood:false})
                    } else if (return_code === 3) {
                        this.$router.push(`/wait-verify`)
                    } else {
                        this.$router.push(`/applications`)
                    }
                }).catch((e) => {
                    this.loading = false
                    if(e.code === 116108){
                        this.$message.error({
                            content: e.detail,
                            closable : true,
                            duration: 0
                        });
                    }else{
                        this.$message.error('Sorry! Something went wrong. Please contact us at support@cloudclusters.io.');
                    }
                })
            }
        },
        created() {
            this.product = products[this.$route.params.product.toLocaleLowerCase()].productName
            this.plan_uuid = this.$route.params.plan_uuid
            console.log(this.clientInfo)

            if (this.clientStatus === 'Uncertain' && !this.checkInfoCompleted()) {
                // go to complete info
                this.$router.push({
                    path: '/unverified',
                    query: {
                        return_url: `/check/${this.product}/${this.plan_uuid}`,
                        processing: '1',
                    }
                })
            } else {
                // this.mod = VpsCheckOut
                this.$store.commit('setState', {payload: {haveOrder: this.$route.path}})
                Promise.all([this.getProduct(), this.getLocation()]).then(() => {
                    let idx = this.plansUUList.indexOf(this.plan_uuid)
                    this.planIdx = idx > -1 ? idx : 0;
                    this.location = this.locationList[0].location_id
                    this.vers = this.versions.length ? this.versions[0].id : null
                    this.ready = true
                })
            }
            this.$SDK.track({
                pageName : 'Check_free',
                productName : this.product
            })
        }
    }
</script>

<style lang="scss">
    .free-trial-page {
        .panel-wrapper {
            background-color: #ffffff;
            .panel-title {
                color: #000000 !important;
            }
        }
        .colored {
            color: #3f51b5 !important;
        }
    }

</style>
