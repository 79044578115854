import { render, staticRenderFns } from "./Fraud.vue?vue&type=template&id=cbd5505a&scoped=true&"
import script from "./Fraud.vue?vue&type=script&lang=js&"
export * from "./Fraud.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbd5505a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/test-tripanels/clusterpanels/client-panel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cbd5505a')) {
      api.createRecord('cbd5505a', component.options)
    } else {
      api.reload('cbd5505a', component.options)
    }
    module.hot.accept("./Fraud.vue?vue&type=template&id=cbd5505a&scoped=true&", function () {
      api.rerender('cbd5505a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/check/Fraud.vue"
export default component.exports