<template>
    <div v-if="ready">
        <component :is="mod"  :client-status="status"></component>
    </div>

</template>

<script>
    import CheckOut from './CheckOut.vue';
    // import VpsCheckOut from './VpsCheckOut.vue'
    import {products} from '@/module/constant'
    import FreeTrial from './FreeTrial.vue';
    import Fraud from './Fraud.vue';
    import {mapState ,mapGetters} from 'vuex' //mapGetters mapActions
    // import Unverified from "./Unverified";

    export default {
        name: "check",
        data() {
            return {
                ready: false,
                mod: null,
                status : null,
            }
        },
        computed: {
            ...mapState('client', ['clientInfo']),
            ...mapGetters('client' , ['checkInfoCompleted']),
        },
        methods: {
            isFreeTrial() {
                this.$http.get(`/clients/${this.clientInfo.id}/status/`,{
                    params: {
                        product_name:this.product
                    }
                }).then(res => {
                    this.status = res.status
                    if (this.product.toLowerCase() === 'mongodb') {
                        this.mod = Fraud //go to fraud
                        this.ready = true
                        return
                    }
                    if (res.is_trial) {
                        this.mod = FreeTrial //go to freetrial
                        this.ready = true
                    } else {
                        if (['Fraud'].indexOf(res.status) !== -1) { //Uncertain
                            this.mod = Fraud //go to fraud
                            this.ready = true
                            return
                        } else {
                            // this.mod = VpsCheckOut
                            this.$store.commit('setState', {payload:{haveOrder:this.$route.path}})
                            this.mod = CheckOut
                            this.ready = true;
                        }
                    }
                })
            }
        },
        created() {
            this.plan_uuid =this.$route.params.plan_uuid
            this.product = this.$route.params.product
            this.isFreeTrial()
            this.$SDK.track({
                pageName : 'Check',
                productName : products[this.$route.params.product.toLocaleLowerCase()].productName
            })
        }
    }
</script>

<style lang="scss">

</style>
