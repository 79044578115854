<template>
    <v-container  v-if="ready" class="checkout">
         <h2 class="mb-3 mt-1">Order Confirmation</h2>
        <v-layout wrap class="panel-wrapper pa-3" style="background-color: #fff">
            <v-flex xs12 sm12 md6>
                <v-layout column >
                    <v-flex  class="fs-2 panel-title" style="color:#333;">Plan</v-flex>
                    <v-flex class="pb-0">
                        <v-radio-group v-model="planName" row class="mt-1">
                            <v-radio
                                    class="py-1"
                                    v-for="(name ,idx) in plansNameList"
                                    :key="idx"
                                    :value="name" color="primary">
                                <template v-slot:label>
                                    <span class="fs-3 font-weight-regular"  :class="{colored: planName === name}">{{name}}</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-flex>
                    <v-flex >
                        <v-layout wrap class="py-2" style="padding-left: 28px; background-color: #E9EEFF;width: 523px;">
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">CPU Cores:</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">{{currentPlan.cpu_description}}</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">Memory:</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">{{currentPlan.memory_description}}</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">Disk:</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">{{currentPlan.disk_description}}</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">Backup Storage:</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">{{currentPlan.backup_storage_description}}</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 pt-1">Replica<template v-if="currentPlan.instance!==1">s</template>:</v-flex>
                            <v-flex xs6 class="font-weight-regular fs-3 py-1">{{currentPlan.instance}}</v-flex>
                        </v-layout>
                    </v-flex>
                    <template  v-if="versions.length">
                        <v-flex pt-3 class="fs-2 panel-title" style="color:#333;">Version</v-flex>
                        <v-flex class="pb-0">
                            <v-radio-group v-model="vers" row class="mt-1">
                                <v-radio
                                        class="py-1"
                                        v-for="ver in versions"
                                        :key="ver.id"
                                        :value="ver.id" color="primary">
                                    <template v-slot:label>
                                        <span class="fs-3 font-weight-regular" :class="{colored: vers === ver.id}">{{`${productDetail.name}@${ver.number}`}}</span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-flex>
                    </template>
                    <template  v-show="showComponent">
                        <v-flex  class="fs-2 panel-title" style="color:#333;">Component</v-flex>
                        <v-flex class="pb-0">
                            <v-radio-group v-model="img_id" row class="mt-1">
                                <v-radio v-for="item in images"
                                         class="py-1"
                                         :key="item.id"
                                         :value="item.id" color="primary">
                                    <template v-slot:label>
                                        <span class="fs-3 font-weight-regular" :class="{colored: img_id === item.id}">
                                            {{ (item.components.map( o => `${o.name}@${o.version}`)).join('__') }}
                                        </span>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-flex>
                    </template>

                    <v-flex  class="fs-2 panel-title" style="color:#333;">Location</v-flex>
                    <v-flex class="pb-0">
                        <v-radio-group v-model="location" row class="mt-1">
                            <v-radio
                                    class="py-1 mt-1"
                                    v-for="(item ,idx) in locationList"
                                    :key="idx"
                                    :value="item.location_id"
                                    color="primary">
                                <template v-slot:label>
                                    <span class="fs-3 font-weight-regular"  :class="{colored: location === item.location_id}">{{item.region}}</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-flex>
                    <v-flex  class="fs-2 panel-title" style="color:#333;">Billing Cycle</v-flex>
                    <v-flex class="pb-0">
                        <v-radio-group v-model="billing_value" row class="mt-1">
                            <v-radio
                                    class="py-1"
                                    v-for="(billing ,idx) in currentPlan.prices_set"
                                    :key="billing.duration"
                                    :value="idx" color="primary">
                                <template v-slot:label>
                                    <span class="fs-3 font-weight-regular" :class="{colored: billing_value === idx}">{{billing.title}}: ${{billing.price_base.toFixed(2)}} / Month</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-flex>
                    <v-flex  class="fs-2 panel-title" style="color:#333;">Payment Method</v-flex>
                    <v-flex class="pb-0">
                        <v-radio-group v-model="payment" row  class="mt-1">
                            <v-radio v-for="(item,idx) in paymentMethods"
                                     class="py-1"
                                     v-bind:key="idx"
                                     :value="idx" color="primary">
                                <template v-slot:label>
                                    <span class="fs-3 font-weight-regular" :class="{colored: payment === idx}">{{item}}</span>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-flex>
                    <v-flex >
                        <v-textarea v-model="notes" background-color="#f1f6f6" label="Notes"  placeholder="Please enter your additional notes or requirements..." box value="" class="mt-1"></v-textarea>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex  xs12 sm12 md5 offset-md1 >
                <div style="display: flex;justify-content: flex-end;">
                    <v-card style="width: 400px">
                        <v-card-text>
                            <v-layout  column pl-2>
                                <v-flex class="mb-3 ">
                                    <div class="font-weight-bold fs-2" style="text-align: center">Order Summary</div>
                                </v-flex>
                                <v-flex>
                                    <v-layout >
                                        <v-flex xs6 class="fs-3 font-weight-regular py-2">Application</v-flex>
                                        <v-flex xs6 class=" fs-3 font-weight-regular py-2">{{productDetail.name}}</v-flex>
                                    </v-layout>
                                    <v-layout >
                                        <v-flex xs6 class="fs-3 font-weight-regular py-2">Plan</v-flex>
                                        <v-flex xs6 class=" fs-3 font-weight-regular py-2">{{currentPlan.name}}</v-flex>
                                    </v-layout>
                                    <v-layout >
                                        <v-flex xs6 class="fs-3 font-weight-regular py-2">Location</v-flex>
                                        <v-flex xs6 class=" fs-3 font-weight-regular py-2">{{currentLocation.region}}</v-flex>
                                    </v-layout>
                                    <v-layout >
                                        <v-flex xs6 class="fs-3 font-weight-regular py-2">Billing Cycle</v-flex>
                                        <v-flex xs6 class=" fs-3 font-weight-regular py-2">{{currentPlan.prices_set[billing_value].title}}</v-flex>
                                    </v-layout>
                                    <v-layout >
                                        <v-flex xs6 class="fs-3 font-weight-regular py-2">Payment Method</v-flex>
                                        <v-flex xs6 class=" fs-3 font-weight-regular py-2">{{paymentMethods[payment]}}</v-flex>
                                    </v-layout>
                                    <v-layout >
                                        <v-flex xs6 class="fs-3 font-weight-regular py-2" >Total</v-flex>
                                        <v-flex xs6 class=" py-2" style="display: inline-block;color:rgb(64,157,226);font-size: 24px;font-weight: bold;" v-if="currentPlan.prices_set">$ <span
                                        >{{currentPlan.prices_set[billing_value].total_price.toFixed(2)}}</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout >
                                        <v-flex xs6 class="fs-3 font-weight-regular py-2">Notes</v-flex>
                                        <v-flex xs6 class=" fs-3 font-weight-regular py-2">{{notes}}</v-flex>
                                    </v-layout>
                                    <v-flex xs12 md12>
                                        <div class="py-3" style="text-align: center">
                                            <v-btn color="primary" large class="ma-0 text-none" @click="checkOut" :loading="loading">Check Out</v-btn>
                                        </div>
                                    </v-flex>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </div>
            </v-flex>
        </v-layout>
        <pending-account-notice ref="pending" :isCheck="true"></pending-account-notice>
    </v-container>
</template>

<script>
    import PendingAccountNotice from '@/components/BillingInfo/PendingAccountNotice'
    import {products} from '@/module/constant'
    import 'vue-slider-component/theme/default.css'
    import {mapGetters} from 'vuex' //mapGetters mapActions


    export default {
        name: "CheckOut",
        components:{PendingAccountNotice},
        data() {
            return {
                loading: false,
                ready: false,
                locationList: [],
                productDetail: {},
                planName: '',
                location:'',
                vers : null,
                images: [],
                img_id : null,
                billing_value: 0,
                payment: 0,
                paymentMethods: ['PayPal', 'Credit Card'],
                notes: ''
            }
        },
        computed: {
            showComponent(){
                if(this.images.length===1 && this.images[0].components.length ===1 ){
                    return false
                }else{
                    return true
                }
            },
            plansUUList() {
                if (this.productDetail.plans) {
                    return this.productDetail.plans.map((item) => {
                        return item.uuid
                    })
                } else {
                    return []
                }
            },
            plansNameList() {
                if (this.productDetail.plans) {
                    return this.productDetail.plans.map((item) => {
                        return item.name
                    })
                } else {
                    return []
                }
            },
            versions (){
                return this.productDetail.versions || []
            },
            currentPlan() {
                return this.productDetail.plans[this.plansNameList.indexOf(this.planName)];
            },
            currentLocation() {
                return this.locationList.find((item)=>item.location_id === this.location)
            },
            ...mapGetters('client' , ['checkInfoCompleted']),
        },
        methods: {
            checkOut() {
                if(this.loading) return
                if (!this.checkInfoCompleted()) {
                    this.$root.billingCpn.open({isGood:false})
                } else {
                    this.loading = true
                    this.$http.post(`/orders/`, {
                        product_uuid: this.productDetail.uuid,
                        plan_uuid: this.currentPlan.uuid,
                        location_id:this.location,
                        price_id: this.currentPlan.prices_set[this.billing_value].id,
                        notes: this.notes,
                        image_id:  this.img_id,
                        payment_method: this.paymentMethods[this.payment]
                    }).then(res => {
                        this.loading = false
                        this.$store.commit('setState', {
                            payload:{haveOrder:''}
                        })
                        if(res.invoice===""){
                            this.$refs.pending.open({isGood:false})
                        }else{
                            this.$router.push(`/invoice-detail/${res.invoice}`);

                        }
                    }).catch(() => {
                        this.loading = false
                        this.$message.error('Sorry! Something went wrong. Please contact us at support@cloudclusters.io.');
                    })
                }

            },
            getProduct() {
                let productName = products[this.$route.params.product.toLocaleLowerCase()].productName ;
                return this.$http.get(`/products/${productName}/`).then(res => {
                    res.plans.sort(( a,b) => a.total_cpus - b.total_cpus)
                    this.productDetail = res
                })
            },
            getLocation(){
               return  this.$http.get('orders/active-location/').then((res) => {
                    this.locationList = res
                })
            }
        },
        watch : {
            vers(newVal){
                if(newVal){
                    let ver =this.versions.find(o => o.id === newVal)
                    this.images = ver.images
                    this.img_id = ver.images[0].id
                }else{
                    this.images = []
                    this.img_id = null
                }
            }
        },
        created() {
            if (!this.checkInfoCompleted()) {
                this.$root.billingCpn.open()
            }
            this.plan_uuid = this.$route.params.plan_uuid;
            Promise.all([this.getProduct(),this.getLocation()]).then(() => {
                if(this.plansUUList.indexOf(this.plan_uuid) === -1){
                    this.plan_uuid = this.plansUUList[0]
                }
                this.location = this.locationList[0].location_id
                this.planName = this.plansNameList[this.plansUUList.indexOf(this.plan_uuid)];
                this.vers =  this.versions.length?this.versions[0].id:null
                this.ready = true
            })
            this.$SDK.track({
                pageName : 'Check_Out',
                productName : products[this.$route.params.product.toLocaleLowerCase()].productName
            })
        }
    }

</script>

<style lang="scss">
    .checkout{
        .colored{
            color: #3f51b5 !important;
        }
        .panel-title {
            color: #000000 !important;
        }
        /*.vue-slider{*/
        /*    .vue-slider-process{*/
        /*        background-color: #3f51b5;*/
        /*    }*/
        /*    .vue-slider-dot-tooltip-inner{*/
        /*        border-color: #3f51b5;*/
        /*        background-color: #3f51b5;*/
        /*    }*/
        /*    .vue-slider-marks .vue-slider-mark{*/
        /*        height: 10px !important;*/
        /*        width: 10px !important;*/
        /*        background-color: rgb(94,124,181);*/
        /*        border-radius: 5px;*/
        /*    }*/
        /*}*/
    }

</style>
